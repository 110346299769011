import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';
import { useCallbackRef } from '@chakra-ui/react';
import {
  applyPolyfills,
  defineCustomElements as jeepSqlite,
} from 'jeep-sqlite/loader';
import pMemoize from 'p-memoize';
import create from 'zustand';

import { StriveDBConfig, StriveSQLMigrations } from './sqlite-schema';

type State = {
  sqlite: SQLiteConnection | null;
  db: SQLiteDBConnection | null;
};

const useStore = create<State>((set) => ({
  sqlite: null,
  db: null,
}));

export const initializeSQLite = pMemoize(_initializeSQLite, {
  cacheKey: JSON.stringify,
});
export const connect = pMemoize(_connect, { cacheKey: JSON.stringify });

export const useDatabase = () => {
  const { sqlite, db } = useStore();

  const saveDB = useCallbackRef(() => {
    if (Capacitor.getPlatform() === 'web') {
      sqlite?.saveToStore(db?.getConnectionDBName() || '');
    }
  });

  useEffect(() => {
    if (!sqlite) {
      initializeSQLite();
    }

    let interval: number | undefined;
    if (!db) {
      (async () => {
        const res = await connect();
        Object.assign(window, res);
      })();

      interval = window.setInterval(() => {
        saveDB();
      }, 60_000);
    }

    return () => clearInterval(interval);
  }, [sqlite, db, saveDB]);

  return { sqlite, db, saveDB };
};

async function _initializeSQLite(wasmPath = '/_assets') {
  await applyPolyfills();
  jeepSqlite(window);

  const sqlite = new SQLiteConnection(CapacitorSQLite);

  if (Capacitor.getPlatform() === 'web') {
    // On Chrome, if you emulate an iOS device,
    // this doesn't work because of an open bug in localforage:
    // https://github.com/localForage/localForage/issues/1053
    // The workaround is to emulate a non-iOS if you want to test this.
    const jeepEl = document.createElement('jeep-sqlite');
    jeepEl.setAttribute('wasmPath', wasmPath);
    jeepEl.wasmPath = wasmPath;
    document.body.appendChild(jeepEl);
    await customElements.whenDefined('jeep-sqlite');
    await sqlite.initWebStore();
  }

  useStore.setState({ sqlite });
  return sqlite;
}

async function _connect(
  config = StriveDBConfig,
  migrations = StriveSQLMigrations,
) {
  const sqlite = await initializeSQLite();

  // Add migrations
  sqlite.addUpgradeStatement(config.database, migrations);

  const { result: isConsistent } = await sqlite.checkConnectionsConsistency();
  const { result: isConnected } = await sqlite.isConnection(
    config.database,
    config.encrypted,
  );

  let db: SQLiteDBConnection | null = null;
  if (isConsistent && isConnected) {
    db = await sqlite.retrieveConnection(config.database, config.encrypted);
  } else {
    db = await sqlite.createConnection(
      config.database,
      config.encrypted,
      config.mode,
      config.version,
      false,
    );
    await db.open();
  }

  useStore.setState({ db });
  return { sqlite, db };
}
