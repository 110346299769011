import { Box } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { PillarPractice } from '@arena-labs/shared-models';
import { ZStack } from '@arena-labs/strive2-ui';

import { AciContext, AciSelector, useAciMachine } from '../aci-context';
import { RechargeEstimateCard } from '../recharge-cards';
import { Estimate } from './estimate';
import { Notes } from './notes';
import { PracticeReview } from './practice-review';

type AciUserInputsProps = {
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
};

export function AciUserInputs({ logPracticeCard }: AciUserInputsProps) {
  const [state, send] = useAciMachine();
  const submissionError = Boolean(state.context.aciSubmissionError);

  const aciState = state.context.aciState;
  const rechargeEstimate = AciSelector.isSkipped(state)
    ? null
    : aciState.recharge_estimate;

  const handlePracticeReviewComplete = () => {
    send({ type: 'submit' });
  };

  const handleACIEstimate = (selection: 'above' | 'below') => {
    send({
      type: 'next',
      data: { aci_start: new Date(), recharge_estimate: selection },
    });
  };

  const handleEstimateNotes = (selection: string) => {
    send({
      type: 'next',
      data: { estimate_notes: selection },
    });
  };

  const view = AciContext.useSelector((state) =>
    state.matches({ Open: { userInputs: 'practiceReview' } })
      ? 'practiceReview'
      : state.matches({ Open: { userInputs: 'estimate' } })
      ? 'estimate'
      : state.matches({ Open: { userInputs: 'notes' } })
      ? 'notes'
      : null,
  );
  const shouldShowEstimateCard =
    view === 'practiceReview' ||
    (view === 'notes' && Boolean(rechargeEstimate));

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {/* transitioning the maxH is like a hack <collapse/>, the ChakraUI collapse is weird */}
      <Box
        maxH={shouldShowEstimateCard ? '300' : '0'}
        transition={'all 0.35s ease'}
        opacity={shouldShowEstimateCard ? 1 : 0}
        mt={shouldShowEstimateCard ? 6 : 0}
      >
        <RechargeEstimateCard selection={rechargeEstimate} />
      </Box>

      <ZStack h={'full'}>
        <AnimatePresence>
          {view === 'estimate' ? (
            <Estimate
              estimate={rechargeEstimate ?? undefined}
              persistSelection={(selection) => handleACIEstimate(selection)}
            />
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {view === 'notes' ? (
            <Notes
              notes={aciState.estimate_notes ?? undefined}
              persistNotes={(selection: string) =>
                handleEstimateNotes(selection)
              }
              estimate={rechargeEstimate ?? undefined}
            />
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {view === 'practiceReview' ? (
            <PracticeReview
              onReviewComplete={handlePracticeReviewComplete}
              submissionError={submissionError}
              logPracticeCard={logPracticeCard}
            />
          ) : null}
        </AnimatePresence>
      </ZStack>
    </Box>
  );
}
