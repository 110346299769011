import { Plugin, PluginListenerHandle } from '@capacitor/core';

export type JStyleLogLevel = 'VERBOSE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';

export const JStyleSensor = {
  HR: 1,
  SPO2: 2,
  TEMPERATURE: 3,
  HRV: 4,
} as const;

export const JStyleSensorMode = {
  Disabled: 0,
  Enabled: 1,
  Intervals: 2,
};

export type JStyleDevice = {
  name: string;
  deviceId: string;
};

export type JStyleDeviceConnected = JStyleDevice & {
  mac: string;
  version?: string;
};

export type JStyleCurrentDevice =
  | ({ state: 'connected' } & JStyleDeviceConnected)
  | { state: Exclude<JStyleDeviceState, 'connected'> };

export type JStyleDeviceUpdate = JStyleDeviceConnected & {
  batteryLevel?: number;
  time?: string;
  automaticMonitoring?: JStyleMonitoringInterval;
};

export type JStyleHrv = {
  date: string;
  hrv: number;
  heartRate: number;
  highBP: number;
  lowBP: number;
  stress: number;
  vascularAging: number;
};

export type JStyleSleep = {
  startTime: string;
  duration: number;
  quality: Array<number>;
  unitLength: number;
};

export type JStyleSingleHr = {
  date: string;
  bpm: number;
};

export type JStyleContinuousHr = {
  date: string;
  bpm: number[];
};

export type JStyleSensorData<Type = unknown> = {
  date: string | null;
  result: Type;
};

export type JStyleMonitoringInterval = {
  dataType: (typeof JStyleSensor)[keyof typeof JStyleSensor];
  workMode: number;
  startTime: string;
  endTime: string;
  intervalTime: number;
  weeks: number;
  days: Record<
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday',
    boolean
  >;
};

type JStyleMonitoringChangeRequest = Prettify<
  Pick<JStyleMonitoringInterval, 'dataType' | 'workMode' | 'intervalTime'> &
    Partial<JStyleMonitoringInterval>
>;

export type JStyleIterable<Type> = Type & {
  _end: boolean;
  _zeroData?: boolean;
};

export type JStyleCallbackId = string;

export type JStyleCallback<DataType> = (
  data: JStyleIterable<DataType> | null,
  error: unknown,
) => void;

export type JStyleDeviceState =
  | 'disconnected'
  | 'connecting'
  | 'connected'
  | 'disconnecting'
  | 'unknown';

export type BluetoothPermission =
  | 'granted'
  | 'denied'
  | 'prompt'
  | 'prompt-with-rationale'
  | 'unknown';
export type BluetoothState =
  | 'on'
  | 'off'
  | 'unauthorized'
  | 'unknown'
  | 'resetting'
  | 'unsupported'
  | 'error';

export interface JStylePlugin extends Plugin {
  // Methods
  setLogLevel(params: { level: JStyleLogLevel }): Promise<void>;

  getLogLevel(): Promise<{ level: JStyleLogLevel }>;

  /** Check if the user has granted permission to use the Bluetooth device. */
  checkPermissions(): Promise<{ permission: BluetoothPermission }>;

  /** Ask the user to grant permission to use the Bluetooth device. */
  requestPermissions(): Promise<{ permission: BluetoothPermission }>;

  /** Wipe all data from the device and reset it to factory settings. */
  factoryReset(): Promise<void>;

  /** Restart the device (MCUReset). */
  reboot(): Promise<void>;

  /**
   * Start scanning for devices.
   *
   * Associated events:
   * `deviceFound` - notifies when a device is found
   * `devicesFound` - notifies all devices that were found so far
   * `scanning` - notifies when scanning starts/stops
   */
  startScan(): Promise<void>;

  /** Stop scanning for devices. */
  stopScan(): Promise<void>;

  /** Check if the device is currently scanning. */
  isScanning(): Promise<{ scanning: boolean }>;

  /**
   * Connect to a device using its deviceId or MAC address.
   * Returns a `JSyleDeviceConnected` object with the device's information.
   * Rejects with an error message if the connection fails.
   *
   * Associated events:
   * `connected` - notifies when device is connected/reconnected
   * `connectFailed` - notifies when connection fails
   * `disconnected` - notifies when device is disconnected
   */
  connect(options: { deviceId: string }): Promise<JStyleDeviceConnected>;

  /**
   * Disconnect from the device.
   * Resolves when the device is disconnected
   *
   * Associated events:
   * `disconnected` - notifies when device is disconnected
   */
  disconnect(): Promise<void>;

  /** Get the current Bluetooth state. */
  getBluetoothState(): Promise<{ state: BluetoothState }>;

  /** Get the current state of the device. */
  getCurrentDevice(): Promise<JStyleCurrentDevice>;

  /** Get the device's MAC address. */
  getMacAddress(): Promise<{ macAddress: string }>;

  /** Get the device's version. */
  getDeviceVersion(): Promise<{ version: string }>;

  /** Get the time set on the device. */
  getDeviceTime(): Promise<{ time: string }>;

  /** Set the time on the device. */
  setDeviceTime(options?: { date?: string }): Promise<void>;

  /** Get the device's battery level. */
  getBatteryLevel(): Promise<{ batteryLevel: number }>;

  /**
   * Get the device's automatic monitoring settings.
   * Use this to check how often the device is monitoring the user's health data.
   *
   * Use `JStyleSensor` to specify the dataType.
   */
  getAutomaticMonitoring(options?: {
    dataType?: JStyleMonitoringInterval['dataType'];
    timeout?: number;
  }): Promise<JStyleMonitoringInterval>;
  setAutomaticMonitoring(options: JStyleMonitoringChangeRequest): Promise<void>;

  /**
   * Get the continuous HR data since `date` (optional).
   * Accepts a callback to receive each data point as it arrives.
   */
  getContinuousHrData(
    callback?: JStyleCallback<JStyleContinuousHr>,
  ): Promise<JStyleCallbackId>;
  getContinuousHrData(
    options?: { date?: string; timeout?: number },
    callback?: JStyleCallback<JStyleContinuousHr>,
  ): Promise<JStyleCallbackId>;

  /**
   * Delete all Continuous HR data from the device.
   */
  deleteContinuousHrData(): Promise<void>;

  /**
   * Get the HRV data since `date` (optional).
   * Accepts a callback to receive each data point as it arrives.
   */
  getHrvData(callback?: JStyleCallback<JStyleHrv>): Promise<JStyleCallbackId>;
  getHrvData(
    options?: { date?: string; timeout?: number },
    callback?: JStyleCallback<JStyleHrv>,
  ): Promise<JStyleCallbackId>;

  /**
   * Delete all HRV data from the device.
   */
  deleteHrvData(): Promise<void>;

  /**
   * Get the Single HR data since `date` (optional).
   * Accepts a callback to receive each data point as it arrives.
   */
  getSingleHrData(
    callback?: JStyleCallback<JStyleSingleHr>,
  ): Promise<JStyleCallbackId>;
  getSingleHrData(
    options?: { date?: string; timeout?: number },
    callback?: JStyleCallback<JStyleSingleHr>,
  ): Promise<JStyleCallbackId>;

  /**
   * Delete all Single HR data from the device.
   */
  deleteSingleHrData(): Promise<void>;

  /**
   * Get the Sleep data since `date` (optional).
   * Accepts a callback to receive each data point as it arrives.
   */
  getSleepData(
    callback?: JStyleCallback<JStyleSleep>,
  ): Promise<JStyleCallbackId>;
  getSleepData(
    options?: { date?: string; timeout?: number },
    callback?: JStyleCallback<JStyleSleep>,
  ): Promise<JStyleCallbackId>;

  /**
   * Delete all Sleep data from the device.
   */
  deleteSleepData(): Promise<void>;

  // Queue management
  /** Get a list of all queued tasks. */
  getQueueDetails(): Promise<{
    queue: Array<{ id: string; taskName: string; keepAlive: boolean }>;
  }>;
  /** Cancel a queued task. */
  cancelCall({ id }: { id: string }): Promise<void>;

  // Event listeners
  addListener(
    eventName: 'bluetoothState',
    /** Listen for changes in the Bluetooth state. */
    listenerFunc: (event: { state: BluetoothState }) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'scanning',
    /** Listen for changes in the scanning state. */
    listenerFunc: (event: { scanning: boolean }) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'deviceFound',
    /** Listen for new devices being found. */
    listenerFunc: (device: JStyleDevice) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'devicesFound',
    /** Listen for all devices found so far during the scan. */
    listenerFunc: (data: { devices: Array<JStyleDevice> }) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'connected',
    /** Listen for when a device is connected. */
    listenerFunc: (info: JStyleDeviceConnected) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'connectFailed',
    /** Listen for when a connection attempt fails. */
    listenerFunc: (info: { error: string; device: JStyleDevice }) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'disconnected',
    /** Listen for when a device is disconnected. */
    listenerFunc: () => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'factoryReset',
    /** Listen for when the device is reset to factory settings. */
    listenerFunc: () => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'deviceUpdate',
    /** @deprecated Listen for updates to the device's information (version, time, etc). */
    listenerFunc: (event: { device: JStyleDeviceUpdate }) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'hrvData',
    /** Listen for when new HRV data record is available. */
    listenerFunc: (data: JStyleHrv) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'hrvDataResult',
    /** Listen for when all HRV data is available. */
    listenerFunc: (
      data: JStyleSensorData<JStyleHrv[]> & { formatted: string },
    ) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'singleHrData',
    /** Listen for when new Single HR data record is available. */
    listenerFunc: (data: JStyleSingleHr) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'singleHrDataResult',
    /** Listen for when all Single HR data is available. */
    listenerFunc: (data: JStyleSensorData<JStyleSingleHr[]>) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'sleepData',
    /** Listen for when new Sleep data record is available. */
    listenerFunc: (data: JStyleSleep) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'sleepDataResult',
    /** Listen for when all Sleep data is available. */
    listenerFunc: (
      data: JStyleSensorData<JStyleSleep[]> & { formatted: string },
    ) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'continuousHrData',
    /** Listen for when new Continuous HR data record is available. */
    listenerFunc: (data: JStyleContinuousHr) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'continuousHrDataResult',
    /** Listen for when all Continuous HR data is available. */
    listenerFunc: (data: JStyleSensorData<JStyleContinuousHr[]>) => void,
  ): Promise<PluginListenerHandle>;
  addListener(
    eventName: 'log',
    /** Listen for log messages from the plugin. */
    listenerFunc: (data: {
      level: JStyleLogLevel;
      message: string;
      tag: string;
      extra?: Record<string, unknown>;
    }) => void,
  ): Promise<PluginListenerHandle>;
}

export type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};
