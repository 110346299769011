import React from 'react';
import NextLink from 'next/link';
import { Center, Flex, Grid, Icon, Link } from '@chakra-ui/react';

import { ChevronRightIcon, TEXT } from '@arena-labs/strive2-ui';

export function ProgressLandingCard({
  children,
  title,
  icon,
  href,
  onClick,
}: {
  children: React.ReactNode;
  title: string;
  icon: React.ReactNode;
  href?: string;
  onClick?: () => void;
}) {
  return (
    <Flex
      w="full"
      borderRadius="card"
      px={5}
      pb={5}
      pt={3.5}
      bg={'#1A1B1D'}
      shadow={'0px -4px 37px 0px #3232324D'}
      flexDir={'column'}
      gap={4}
    >
      <LinkButton href={href} onClick={onClick}>
        <Grid
          w="full"
          templateColumns="auto 1fr auto"
          alignItems={'center'}
          gap={2}
        >
          {icon}
          <TEXT.P1_SEMIBOLD
            textAlign={'left'}
            lineHeight="normal"
            color={'neutral.white'}
          >
            {title}
          </TEXT.P1_SEMIBOLD>
          <Center h={'full'} pr={2}>
            <Icon
              as={ChevronRightIcon}
              w={'11px'}
              h={'auto'}
              color="neutral.600"
            />
          </Center>
        </Grid>
      </LinkButton>
      {children}
    </Flex>
  );
}

function LinkButton({
  children,
  href,
  onClick,
}: {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
}) {
  if (href) {
    return (
      <Link as={NextLink} href={href}>
        {children}
      </Link>
    );
  } else if (onClick) {
    return (
      <Flex as={'button'} onClick={() => onClick()}>
        {children}
      </Flex>
    );
  } else {
    return null;
  }
}
