import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { once } from 'lodash-es';
import {
  ActorRefFrom,
  assign,
  createMachine,
  InterpreterFrom,
  spawn,
} from 'xstate';
import { z } from 'zod';

import { AnalyticsClient, AppTrackingEvent } from '@arena-labs/analytics';
import { initializeHomepageService } from '@arena-labs/strive2-coaching';
import { authMachine, setAuthService } from '@strive/api';

type PassportManagerMachineSchema = {
  context: {
    authActorRef: ActorRefFrom<typeof authMachine> | null;
    homepageCleanup: (() => void) | null;
  };
  events:
    | { type: 'START' }
    | { type: 'LOGGED_IN' }
    | { type: 'LOGGED_OUT' }
    | { type: 'OFFLINE' }
    | { type: 'RESOLVED' };
  services: {
    initializeHomepage: { data: () => void };
  };
};

export const passportManagerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUAuAnAlgNzAAgAUBDWWABwHt1U8BZIgOyJnQGIAZAeQHFuBRACIB9AJIA5ANoAGALqJQlWJlSYKDeSAAeiACwAmADQgAnogDMANikA6fQE4AHA512AjHodmpOiwF9fRmhYuIQk5FQ09EwsHDz8wpwAqgAq0nJIIIrKquoZ2gj6RqYIrjoO1nYA7BbuFpUOrg6VUg56-oEYOPjEpJTUdIzMYGycAGKj7OJ8aRpZKmoa+YUmiBYOAKy21XqOpfVSeq7tIEFdob0RA9HD1gCCAK6oABZgDCoAxkSokKwASnzITjsABqghmGTmOUWiEO5Ts6wslkOUkcehRRXMOkqFR2dikB3hLj0OmOpxCPXC-SiQ3Qd0eLzemE+3wg1gA6kRsgwoHgAGZUPAACQoAFswGQhqwIGowNZMAxsBQANaysndMJ9SKDFh056vD5fSDszkqbl8gXCsUSmAIeWK5k5NLghQUJTzXKgJaVbFSSrrNz6LzrSqWMwYhBmJzWOotPHrVzuHRmeGkzrkjWXak6h56xnMo0crk8-noIWi8WS4boKjWMgAGy+JZF1jV50pWuutJzDINLONRfNpctFZtdooDrUTtks1d2QWeV03usvv9pT0QZDiPD6x21jMrn9Ulcbk3lTaAROafVFyp2pu3f1TMNrMLpuLFvL1rArE0sFQhusIheW+dAAApXHxfEAEpWFbClNSuGldR7J8+1feV3yHT8hmdTJZ3daECiXFcA3XKRg1DcMylcaxPB0FE7DsJN1jMYlU2Ca920QnUCFeCAMNg5Jbl+VJpwhfCoQXEp1k2HR6MqFwLGo-RSnDapsXhdYkx8dx3GqfwLwYCgIDgDQ4IzW9OxnN1JM9RAAFoLHDRz2LOeDMzvWk0CIagMOsucPS0cx1hsJErATOwLD0YNDBWBBKjcawdl9VipDMMpqgcVz0xvDskIfPNn38gipNcZoKgRJEINRdE4sOMxbBknY9BqLxIqxbLOIQrN73pR98xfE0MMHMsrSGYrbKChBYQqxEouq1pauKEKLCSvEWp0RoLAsYMdHWTq226zzkP659rF+MAiAgYoXRs+c7PitLrAPGSnFcCw7D0BKwziswQoqSNWmcHbGjcA73Ms-K+sKvtkkwMUKEeCb7qm5oGpe9Y3o+r67B+5b3GsYNGODBKtr+8GLLynV2AoKAYAgPBEdQZHAvyNFNiUrEXGPSKDhqcN3E2KoHCY1xI0Oaj9ovczcu4m5eIYfjuRZwjlmKUm1pStF0qaNYKdlnraU4XleTreUwBV0q6mXCDnHcX0QycWLim2vQtjcBN0simTKgM3wgA */
  createMachine({
    context: {
      authActorRef: null,
      homepageCleanup: null,
    },
    tsTypes: {} as import('./passport-manager.machine.typegen').Typegen0,
    schema: {} as PassportManagerMachineSchema,
    id: 'Strive Passport Manager',
    initial: 'Pending',
    description: 'This machine manages Auth + User Journey machines',
    predictableActionArguments: true,

    states: {
      Starting: {},
      Authenticated: {
        initial: 'Waiting for Homepage',
        exit: 'cleanupHomepage',
        states: {
          'Waiting for Homepage': {
            invoke: {
              src: 'initializeHomepage',
              onDone: {
                actions: 'saveHomepageCleanup',
                target: 'Ready',
              },
              onError: {
                actions: [(context, event) => console.error(event)],
                target: 'Timeout',
              },
            },
            after: {
              '10000': {
                target: 'Timeout',
              },
            },
          },
          Ready: {
            entry: 'hideSplashScreen',
            description: 'We have all the data required to show logged-in UI',
          },
          Timeout: {
            entry: 'hideSplashScreen',
          },
        },
        on: {
          RESOLVED: {
            target: '.Ready',
          },
        },
      },
      'Logged out': {
        entry: 'hideSplashScreen',
      },
      Pending: {
        on: {
          START: {
            actions: ['spawnAuthMachine'],
            target: 'Starting',
          },
        },
      },
      Offline: {
        entry: 'hideSplashScreen',
      },
    },
    on: {
      LOGGED_IN: {
        target: '.Authenticated',
      },
      LOGGED_OUT: {
        target: '.Logged out',
      },
      OFFLINE: {
        target: '.Offline',
      },
    },
  }).withConfig({
    services: {
      initializeHomepage: () => initializeHomepageService(),
    },
    actions: {
      spawnAuthMachine: assign({
        authActorRef: (context, event) => {
          performance.mark('auth:starting');
          const authService = spawn(
            authMachine.withContext({
              ...authMachine.context,
            }),
            { name: 'auth' },
          );
          setAuthService(authService as InterpreterFrom<typeof authMachine>);
          return authService;
        },
      }),
      saveHomepageCleanup: assign({
        homepageCleanup: (context, event) => event.data,
      }),
      cleanupHomepage: assign({
        homepageCleanup: (context) => {
          if (context.homepageCleanup) {
            context.homepageCleanup();
          }
          return null;
        },
      }),
      hideSplashScreen: (context) => {
        performance.mark('splash-screen:hide');
        if (Capacitor.isPluginAvailable('SplashScreen')) {
          SplashScreen.hide();
        }
        reportAppMetrics(new AnalyticsClient({ isEnabled: true }));
      },
    },
  });

const reportAppMetrics = once(function reportAppMetrics(
  analytics: AnalyticsClient,
) {
  try {
    const getMark = (name: string) => performance.getEntriesByName(name)[0];
    const measure = (name: string, start: string, end: string) => {
      if (getMark(start) && getMark(end)) {
        return performance.measure(name, start, end);
      }
    };
    const cacheMark = z
      .object({
        detail: z.object({ cacheSize: z.number(), timestamp: z.number() }),
      })
      .safeParse(getMark('query-client:restored'));
    const round = (n: number | null | undefined) =>
      typeof n === 'number' ? Math.round(n) : n;

    const safeAreaInsets = getSafeAreaInsets();

    analytics.logEvent(AppTrackingEvent.AppStartupMetrics, {
      // The time taken from webview creation until _app.js is loaded
      appStarting: round(getMark('app:bootup:start')?.startTime),

      // The time from webview creation to removing the splash screen
      appReady: round(getMark('splash-screen:hide')?.startTime),

      // How long it took to restore the react-query cache
      cacheLoadTime: round(
        measure(
          'query-client:cache',
          'query-client:restoring',
          'query-client:restored',
        )?.duration,
      ),
      // Size in kb of persisted react-query cache
      cacheSize: cacheMark.success ? cacheMark.data.detail.cacheSize : null,
      // Age in ms of persisted react-query cache
      cacheAge: cacheMark.success
        ? +new Date() - cacheMark.data.detail.timestamp
        : null,

      // How long it took to resolve the auth state and remove the splash screen
      authTime: round(
        measure('auth', 'auth:starting', 'splash-screen:hide')?.duration,
      ),

      screenWidth: window.outerWidth,
      screenHeight: window.outerHeight,
      screenInsetTop: safeAreaInsets.top,
      screenInsetRight: safeAreaInsets.right,
      screenInsetBottom: safeAreaInsets.bottom,
      screenInsetLeft: safeAreaInsets.left,
    });
  } catch (e) {
    // ignore
    console.error(e);
    console.log('performance', performance.getEntries());
  }
});

function getSafeAreaInsets() {
  // Create a temporary element to get the safe area insets
  const tempElement = document.createElement('div');

  tempElement.style.paddingTop = 'env(safe-area-inset-top, 0px)';
  tempElement.style.paddingRight = 'env(safe-area-inset-right, 0px)';
  tempElement.style.paddingBottom = 'env(safe-area-inset-bottom, 0px)';
  tempElement.style.paddingLeft = 'env(safe-area-inset-left, 0px)';

  document.body.appendChild(tempElement);

  const style = getComputedStyle(tempElement);
  const safeAreaInsets = {
    top: parseFloat(style.paddingTop) || 0,
    right: parseFloat(style.paddingRight) || 0,
    bottom: parseFloat(style.paddingBottom) || 0,
    left: parseFloat(style.paddingLeft) || 0,
  };

  // Cleanup the temporary element
  document.body.removeChild(tempElement);

  return safeAreaInsets;
}
