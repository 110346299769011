import 'swiper/css';
import 'swiper/css/effect-fade';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useReducedMotion } from 'framer-motion';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AppGateProps } from '@strive/api';

import { CompletionCompletionScreen } from './completion-screen';
import { CompetitionCompletionStats } from './completion-stats';

export type CompetitionCompletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  stats: AppGateProps<'competition_completed'>;
};

export function CompetitionCompletionModal({
  isOpen,
  onClose,
  stats,
}: CompetitionCompletionModalProps) {
  const prefersReducedMotion = useReducedMotion();

  return (
    <Drawer isOpen={isOpen} size="full" placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody h="full" pt="env(safe-area-inset-top)" px="0">
          <Swiper
            style={{ width: '100%', height: '100%' }}
            allowTouchMove={false}
            effect={prefersReducedMotion ? 'fade' : 'slide'}
            modules={prefersReducedMotion ? [EffectFade] : undefined}
          >
            <SwiperSlide>
              {stats.is_winner ? (
                <CompetitionWinner />
              ) : (
                <CompetitionFinisher />
              )}
            </SwiperSlide>
            <SwiperSlide>
              <CompetitionCompletionStats onClose={onClose} stats={stats} />
            </SwiperSlide>
          </Swiper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function CompetitionWinner() {
  return (
    <CompletionCompletionScreen
      lottie={import('./winners.lottie.json')}
      messages={[
        {
          title: 'Nice Work!',
          body: "You've finished in the top five on the Strive Leaderboard!",
        },
      ]}
    />
  );
}

function CompetitionFinisher() {
  return (
    <CompletionCompletionScreen
      lottie={import('./finishers.lottie.json')}
      messages={[
        {
          title: 'Well Done!',
          body: "We're grateful for your participation and dedication in the Strive Leaderboard!",
        },
      ]}
    />
  );
}
