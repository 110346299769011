import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  TEXT,
  useFadeEntryFadeExit,
  useResponsive,
} from '@arena-labs/strive2-ui';

export function Notes({
  notes,
  persistNotes,
  estimate,
}: {
  notes?: string;
  persistNotes: (notes: string) => void;
  estimate?: string;
}) {
  const rs = useResponsive();

  const { scope, animate } = useFadeEntryFadeExit();
  const analytics = useAnalytics();
  const notesSchema = z.object({
    notes: z
      .string()
      .trim()
      .refine(
        (val) => val.length <= 200,
        (val) => ({
          message: `Exceeded character limit: ${val.length}/200 chars`,
        }),
      ),
  });
  const form = useForm<z.infer<typeof notesSchema>>({
    resolver: zodResolver(notesSchema),
    defaultValues: { notes },
  });
  const currentNote = useWatch({ control: form.control, name: 'notes' });

  useEffect(() => {
    animate(
      '#skipButton',
      { opacity: 1, y: ['200px', '0px'] },
      { duration: 0.33, delay: 0.5 },
    );
  }, [animate]);
  return (
    <Flex
      ref={scope}
      flexGrow={1}
      as="form"
      onSubmit={form.handleSubmit((data) => {
        persistNotes(data.notes);
      })}
    >
      <Flex direction={'column'} h={'full'}>
        <TEXT.P1_SEMIBOLD mt={'10'} color={'logo'}>
          2 of 3
        </TEXT.P1_SEMIBOLD>
        <Box mt={4} mb={10}>
          <TEXT.H2>
            Reflect on the factors that contribute to your selection.
          </TEXT.H2>
          <TEXT.P3 mt={2} color={'neutral.400'}>
            (Optional)
          </TEXT.P3>
        </Box>
        <FormControl isInvalid={Boolean(form.formState.errors.notes)}>
          <Textarea
            {...form.register('notes')}
            border={'1px solid'}
            borderColor={'neutral.900'}
            bg={'elevation.1dp'}
            h={rs({ xs: '130px', base: '200px' })}
            p={3}
            placeholder={
              estimate === 'above'
                ? "'...ate over 3 hours before bed, hydrated well, spoke to friends/loved ones, engaged in breathing techniques, low key work day/day off...'"
                : "'...didn’t sleep well, heavy case load, home stressors, difficult workout close to bed, not enough light exposure...'"
            }
          />
          <FormErrorMessage>
            {form.formState.errors.notes?.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          variant={'underline-link'}
          color={'neutral.white'}
          mt="auto"
          mb={6}
          id={'skipButton'}
          opacity={0}
          onClick={() => {
            analytics.log(DataTrackingEvent.ReflectionSkippedWithinACI);
          }}
        >
          Skip
        </Button>

        <Button
          type="submit"
          isDisabled={!currentNote}
          variant={'primary'}
          w={'full'}
          onClick={() => {
            notes &&
              analytics.log(DataTrackingEvent.ReflectionWithinACIConfirmed, {
                reflection: notes,
              });
          }}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}
