import { createActorContext } from '@xstate5/react';
import { isToday } from 'date-fns';
import { SnapshotFrom } from 'xstate5';

import { aciMachine } from './aci2.machine';

export const AciContext = createActorContext(aciMachine);

export function useAciMachine() {
  const actor = AciContext.useActorRef();
  const state = AciContext.useSelector((state) => state);
  return [state, actor.send, actor] as const;
}

export const AciSelector = {
  isLoading: (state: SnapshotFrom<typeof aciMachine>) => {
    return state.matches('Loading');
  },
  isSkipped: (state: SnapshotFrom<typeof aciMachine>) => {
    const { aciResponse } = state.context;
    return (
      state.matches({ Closed: 'skipping' }) ||
      (aciResponse && isToday(aciResponse.created_at) && aciResponse.skipped)
    );
  },
  isComplete: (state: SnapshotFrom<typeof aciMachine>) => {
    const { aciResponse } = state.context;
    return (
      aciResponse &&
      isToday(aciResponse.created_at) &&
      !aciResponse.skipped &&
      Boolean(aciResponse.daily)
    );
  },
  canResume: (state: SnapshotFrom<typeof aciMachine>) => {
    if (AciSelector.isSkipped(state)) return false;
    if (AciSelector.isComplete(state)) return false;
    const { aciState } = state.context;
    return Boolean(aciState.recharge_estimate);
  },
};
