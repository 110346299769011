import { Capacitor } from '@capacitor/core';
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateInfo,
} from '@capawesome/capacitor-app-update';
import {
  CapacitorUpdater,
  LatestVersion as CapgoLatest,
} from '@capgo/capacitor-updater';
import { useMachine } from '@xstate/react';
import semver from 'semver';
import { assign, createMachine, MachineOptionsFrom } from 'xstate';

import { reportErrorSentry } from '../../lib/sentry';

type CapgoCurrent = Awaited<ReturnType<(typeof CapacitorUpdater)['current']>>;

type OnActions<T> = {
  [K in keyof T as K extends `on${string}` ? K : never]: T[K];
};
type Handlers = OnActions<
  MachineOptionsFrom<typeof checkAppUpdateMachine>['actions']
>;

export function useCheckAppUpdateMachine(actions?: Partial<Handlers>) {
  return useMachine(checkAppUpdateMachine, { actions });
}

export const checkAppUpdateMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGEAWYDGBrABAQQAcCcBVAiAQwBcwBiAZSooCcqBtABgF1FQCB7WAEsqQ-gDteIAB6IALACYANCACe8gMwBGAHQBOBRoVyArFoDs5gBzWTAXzsq0mXIWJlKNHc+xDxUWggJMB0-ADd+LBCfVyJScmpo9F9-BHD+DGoxcU4uXKkBYVEJKVkEOS0rHS09cz0NKwVzADZW8zkVdQQavR1mkzkNbXMTDT0rDis5Bydk2PcErxi-AKDxEPSo7zn8OI9E7ZcVtPEIzOKc7jYtHiQQQpFs0vkFKpq6hqbW5vbOxA0OCZqhpmlomgoOBw5Bw6jMQDFdgtPEkjv5AsFQqdIijsIj4sjDikoCczlkJLk2ApbnxBI8Sncyoo3rV6o0Wm0Omp5FoOH0LHoYZCOMYjHCEW58Qdlmi1hssVtxXtFjisMd0udshSNNT7rSLs9ypVqizPuyfpyuiZQdU6uMNIMhlMNGKdhL9ks5itaGBmMx+MwdAQADbUABm-oAtoT5pKPajieqyZc8twCnqngyXsyPmzvr8uQgzBo+noLBxmoKbCCHI4QOJ+BA4FJFUjEmmihnQGUALTNP4IbvGHRCkej4Uulx490hACSECDYHbdMkmfKygLcgMOgqrwsNh+PWmtZbsZVKyX+tXWgqwIqWjMVi0Q3LfY3k30TWvWmaTQa9+aE64m6yo6AASmAFAQF0NIdvSXaINY5jDjUJhWFYoyKM09r9gMcjbpMBjXuC356IBMbTjo074GEFBCCGABGC4Xp2MiIFMCg2uWgJWAKUx6P2WHbuh5gcCCwnNHozRWGRU4gVReA0XRFCMSEAAKfoRgQVA4AArsqzFwaxCA8byZYVqhvGbv2ALFnIpggpUTQDFhMnAQS8mKQxC46PQdFgOI2l6ciBkrvBxkCpx5kmXx-bQlUBjmF+P7mH+AHHq6Srucq1G0V5IQAGL+hgYC6QQUDMJBi53A8l5hSZkXcZZ-EFu8+hYahEJNIlHC1DWdhAA */
    id: 'Check App Update',

    tsTypes: {} as import('./check-app-update.machine.typegen').Typegen0,

    schema: {
      context: {} as {
        capgoCurrent?: CapgoCurrent;
        capgoLatest?: CapgoLatest;
        appUpdate?: AppUpdateInfo;
        currentVersion?: string;
        latestVersion?: string;
      },
      events: {} as { type: 'Start' },
      services: {} as {
        CheckAvailableVersions: {
          data: {
            capgoLatest?: CapgoLatest;
            capgoCurrent?: CapgoCurrent;
            appUpdate?: AppUpdateInfo;
            currentVersion?: string;
            latestVersion?: string;
          };
        };
      },
    },

    context: {},
    initial: 'Idle',

    states: {
      Idle: {},

      Checking: {
        invoke: {
          src: 'CheckAvailableVersions',
          onDone: [
            {
              target: 'Update Available.Force upgrade',
              cond: 'isNewMajorVersion',
              actions: 'saveUpdateData',
            },
            {
              target: 'Update Available.Prompt update',
              cond: 'isMinorUpdate',
              actions: 'saveUpdateData',
            },
            {
              target: 'Update Available.Silent update',
              cond: 'isPatchUpdate',
              actions: 'saveUpdateData',
            },
            {
              target: 'Ready',
              actions: 'saveUpdateData',
            },
          ],
          onError: 'Ready',
        },
      },

      Ready: {
        entry: 'onReady',
      },

      'Update Available': {
        states: {
          'Prompt update': {
            entry: 'onPromptUpdate',
          },
          'Silent update': {
            entry: 'onSilentUpdate',
          },
          'Force upgrade': {
            entry: 'onForceUpgrade',
          },
        },

        initial: 'Prompt update',
        entry: 'onUpdateAvailable',
      },
    },

    on: {
      Start: '.Checking',
    },
  },
  {
    services: {
      CheckAvailableVersions: async (context, event) => {
        const platform = Capacitor.getPlatform();

        if (platform === 'web') {
          throw new Error('App updates are not available on web');
        }

        const fetchCapgoLatest = async () => {
          try {
            const capgoLatest = await CapacitorUpdater.getLatest();
            return { capgoLatest, latestVersion: capgoLatest.version };
          } catch (error) {
            if (
              error instanceof Error &&
              error.message != 'No new version available'
            ) {
              reportErrorSentry(error);
            }
            return {};
          }
        };

        const fetchCapgoCurrent = async () => {
          try {
            const capgoCurrent = await CapacitorUpdater.current();
            const currentVersion =
              capgoCurrent?.bundle.version === 'builtin'
                ? capgoCurrent.native
                : capgoCurrent.bundle.version;
            return { capgoCurrent, currentVersion };
          } catch (error) {
            reportErrorSentry(error);
            return {};
          }
        };

        const fetchAppUpdateInfo = async () => {
          try {
            const appUpdate = await AppUpdate.getAppUpdateInfo();
            return { appUpdate };
          } catch (error) {
            reportErrorSentry(error);
            return {};
          }
        };

        const [capgoLatestData, capgoCurrentData, appUpdateData] =
          await Promise.all([
            fetchCapgoLatest(),
            fetchCapgoCurrent(),
            fetchAppUpdateInfo(),
          ]);
        return {
          ...appUpdateData,
          ...capgoLatestData,
          ...capgoCurrentData,
        };
      },
    },
    guards: {
      isNewMajorVersion: (context, event) => {
        const { appUpdate } = event.data;

        // Is there a new version in the app store?
        if (
          appUpdate?.updateAvailability !==
          AppUpdateAvailability.UPDATE_AVAILABLE
        ) {
          return false;
        }

        const platform = Capacitor.getPlatform();
        if (platform === 'android') {
          const currentVersion = appUpdate.currentVersionCode;
          const availableVersion =
            appUpdate.availableVersionCode ?? currentVersion;

          // If the platform is Android, compare the major version numbers
          // extracted from the available and current versionCodes.
          // See `scripts/lib/android.ts` for how we encode the version into the versionCode.
          const newMajor = Math.floor(
            parseInt(availableVersion, 10) / 1_000_000,
          );
          const currentMajor = Math.floor(
            parseInt(currentVersion, 10) / 1_000_000,
          );
          return newMajor > currentMajor;
        } else if (platform === 'ios') {
          const currentVersion = appUpdate.currentVersionName;
          const availableVersion =
            appUpdate.availableVersionName ?? currentVersion;
          return semver.diff(currentVersion, availableVersion) === 'major';
        }

        return false;
      },
      isMinorUpdate: (context, event) => {
        const { currentVersion, latestVersion } = event.data;
        if (!currentVersion || !latestVersion) return false;
        const diff = semver.diff(currentVersion, latestVersion);
        return Boolean(diff && ['minor', 'preminor'].includes(diff));
      },
      isPatchUpdate: (context, event) => {
        const { currentVersion, latestVersion } = event.data;
        if (!currentVersion || !latestVersion) return false;
        const diff = semver.diff(currentVersion, latestVersion);
        return Boolean(diff && ['patch', 'prepatch'].includes(diff));
      },
    },
    actions: {
      saveUpdateData: assign((context, event) => event.data),
      onReady: (context, event) => null,
      onUpdateAvailable: (context, event) => null,
      onPromptUpdate: (context, event) => null,
      onSilentUpdate: (context, event) => null,
      onForceUpgrade: (context, event) => null,
    },
  },
);
