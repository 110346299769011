import { useEffect, useState } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import {
  AnimationSequence,
  stagger,
  useAnimate,
  useReducedMotion,
} from 'framer-motion';
import { useSwiper } from 'swiper/react';

import { LottieFile } from '@arena-labs/strive2-ui';

type CompletionMessage = {
  title: string;
  body: string;
};
export type CompletionCompletionScreenProps = {
  lottie: unknown;
  messages: [CompletionMessage, ...Array<CompletionMessage>];
};

export function CompletionCompletionScreen({
  lottie: lottie,
  messages,
}: CompletionCompletionScreenProps) {
  const [scope, isAnimating] = useRevealAnimation(messages.length);
  const swiper = useSwiper();

  return (
    <Flex
      ref={scope}
      direction="column"
      bg="bg.primary"
      gap="4"
      align="center"
      justify="space-between"
      textAlign="center"
      pt="8"
      pb="4"
      px="6"
      height="full"
      maxHeight="full"
      overflow={isAnimating ? 'hidden' : 'visible'}
    >
      <LottieFile
        as={lottie}
        options={{ loop: false }}
        className="image"
        m="0"
      />
      <Flex direction="column" gap="4" justify="space-evenly" height="full">
        {messages.map(({ title, body }, idx) => (
          <Flex key={idx} className="message" direction="column" gap="2">
            <Text textStyle="h2" color="white">
              {title}
            </Text>
            <Text textStyle="h3" color="neutral.400">
              {body}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Button
        variant="primary"
        width="full"
        mt="auto"
        onClick={() => swiper.slideNext()}
      >
        Competition Summary
      </Button>
    </Flex>
  );
}

// This defines the animation timeline for the initial screen
function useRevealAnimation(messageCount: number) {
  const [scope, animate] = useAnimate();
  const prefersReducedMotion = useReducedMotion();
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const fadeIn = { opacity: [0, 1] };
    const slideAndFade = { ...fadeIn, y: [50, 0] };
    const reveal = prefersReducedMotion ? fadeIn : slideAndFade;

    const start = 0.5;
    let sequence: AnimationSequence = [
      // Start by fading in the image and the first message
      ['.image', fadeIn, { at: start }],
      ['.message:first-of-type', fadeIn, { at: start }],
    ];

    // Scale the image down to allow more space for messages
    const imageTransform = {
      scale: 0.75,
      transformOrigin: 'top center',
      marginBottom: '-50%',
    };
    const imageTransition = { at: '+2', duration: 1.5 };
    if (prefersReducedMotion) {
      // apply transform immediately so there's no layout shift
      sequence.push(
        ['.image', imageTransform, { at: start, duration: 0 }],
        ['.pause', {}, imageTransition],
      );
    } else {
      sequence.push(['.image', imageTransform, imageTransition]);
    }

    // Reveal additional messages
    if (messageCount > 1) {
      sequence.push([
        '.message:not(:first-of-type)',
        reveal,
        { at: '+1', delay: stagger(3), duration: 1 },
      ]);
    }

    sequence.push(['button', reveal, { at: '-1', duration: 1 }]);

    const controls = animate(sequence);

    controls.then(() => setIsAnimating(false));

    return () => controls.cancel();
  }, [animate, messageCount, prefersReducedMotion]);

  return [scope, isAnimating] as const;
}
