import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  PermissionStatus,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { useCallbackRef } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import create from 'zustand';

import { useAppStateChange } from '@arena-labs/strive2-ui';

const isPushAvailable = Capacitor.isPluginAvailable('PushNotifications');

export type UsePushPermissionsOptions = {
  onSuccess?: (permission: PermissionStatus['receive']) => void;
};

/**
 * Query to check push notification permissions
 */
export function usePushPermissions(options: UsePushPermissionsOptions = {}) {
  const query = useQuery({
    queryKey: ['push', 'permissions'],
    queryFn: () => {
      return PushNotifications.checkPermissions();
    },
    select: (status) => status.receive,
    enabled: isPushAvailable,
  });

  const successCb = useCallbackRef(options.onSuccess);
  useEffect(() => {
    if (query.data) {
      successCb(query.data);
    }
  }, [query.data, successCb]);

  // Make sure it's up to date when the app becomes active
  useAppStateChange((active) => {
    if (active && !query.isLoading) {
      query.refetch();
    }
  });

  return query;
}

/**
 * Query mutation to request push notification permissions
 */
export function usePushRequestPermissions() {
  const queryClient = useQueryClient();
  const webPlatformResponse: PermissionStatus = { receive: 'granted' };
  return useMutation({
    mutationFn: async () => {
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        return PushNotifications.requestPermissions();
      } else {
        console.info(
          '⚠️ Push notifications are not available on this platform, simulating a success',
        );
        return Promise.resolve(webPlatformResponse);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['push', 'permissions']);
    },
  });
}

type TokenStore = {
  token: Token | undefined;
  actions: {
    setToken: (token: Token) => void;
  };
};

const usePushTokenState = create<TokenStore>((set, get) => ({
  token: undefined,
  actions: {
    setToken: (token: Token) => {
      set({ token });
    },
  },
}));

export function usePushToken(onChange?: (token?: Token) => void) {
  const token = usePushTokenState((state) => state.token);
  const { setToken } = usePushTokenState((state) => state.actions);

  // Register for push notifications if permission is granted
  usePushPermissions({
    onSuccess(status) {
      if (status === 'granted') {
        PushNotifications.register();
      }
    },
  });

  // Handle token change
  const handleChange = useCallbackRef((changed: Token) => {
    if (changed.value !== token?.value) {
      setToken(changed);
      onChange?.(changed);
    }
  });

  // Listen for token registration
  useEffect(() => {
    if (!isPushAvailable) {
      return;
    }

    const onRegister = PushNotifications.addListener('registration', (data) => {
      handleChange(data);
    });

    return () => void onRegister.then((listener) => listener.remove());
  }, [handleChange]);

  return token;
}
