import {
  JStyleCallback,
  JStyleCallbackId,
  JStyleIterable,
  JStyleSensorData,
} from '../definitions';
import { JStyle } from '../jstyle';

type JStyleDataFunction<Options extends {}, DataType> = (
  options: Options | undefined,
  callback: JStyleCallback<DataType>,
) => Promise<JStyleCallbackId>;

export function getDataIterator<Options extends {}, DataType>(
  getData: JStyleDataFunction<Options, DataType>,
  resultEventName:
    | 'hrvDataResult'
    | 'sleepDataResult'
    | 'singleHrDataResult'
    | 'continuousHrDataResult',
) {
  return (options?: Options & { signal?: AbortSignal }) => {
    let callbackId: JStyleCallbackId | null = null;
    let error: unknown = null;

    const abortError = new Error('Aborted');
    async function abort(thisError: unknown = abortError) {
      error ??= thisError;
      if (callbackId) {
        await JStyle.cancelCall({ id: callbackId });
        callbackId = null;
      }
    }

    const { signal, ...restOptions } = options ?? {};
    signal?.addEventListener('abort', () => abort());

    return {
      abort,
      [Symbol.asyncIterator]: async function* () {
        let dataQueue: JStyleIterable<DataType>[] = [];

        if (error || signal?.aborted) {
          // If the task was cancelled before the iterator was started, throw cancellation error
          throw error || abortError;
        }

        const zeroDataTemporaryWorkaroundListener = await JStyle.addListener(
          resultEventName as any,
          (data: JStyleSensorData<unknown[]>) => {
            if (data.result.length === 0) {
              dataQueue.push({
                _end: true,
                _zeroData: true,
              } as JStyleIterable<DataType>);
            }
          },
        );

        // Start the data retrieval task, & set up the receiver
        callbackId = await getData(
          restOptions as Options,
          (data, dataError) => {
            if (dataError) {
              abort(dataError);
            } else if (data) {
              dataQueue.push(data);
            }
          },
        );

        const nextTick = () =>
          new Promise(resolve => requestAnimationFrame(resolve));

        try {
          // Yield each item from the data queue
          while (true) {
            if (error) {
              // Task was cancelled, or an error occurred (plugin might have timed out the task)
              throw error;
            }

            const next = dataQueue.shift();
            if (!next) {
              await nextTick();
              continue;
            }
            if (next._zeroData) {
              // No data was available, break the loop
              break;
            }

            // Yield the data
            yield next;

            if (next._end) {
              // No more data is available, break the loop
              break;
            }
          }
        } finally {
          callbackId = null;
          dataQueue = [];
          await zeroDataTemporaryWorkaroundListener.remove();
        }
      },
    };
  };
}
