import { Grid, Icon, Text } from '@chakra-ui/react';

import { TargetIcon } from '@arena-labs/strive2-ui';

import { CompetitionStar } from '../../incentives/competition/competition-star';
import { useIncentiveHub } from '../../incentives/use-incentive-hub';
import { ProgressLandingCard } from './progress-landing-card';

export function ProgressLandingCompetitionCard({
  name,
  rank,
  score,
}: {
  name: string;
  rank: number;
  score: number;
}) {
  const incentiveHub = useIncentiveHub();

  return (
    <ProgressLandingCard
      title={'Leaderboard'}
      icon={
        <CompetitionStar
          positionValue={rank}
          size={'list'}
          showOutline={true}
        />
      }
      onClick={() => incentiveHub.onOpen()}
    >
      <Grid
        pb={1}
        templateColumns={'1fr auto auto'}
        gap={2}
        borderBottom={'1px solid'}
        alignItems={'center'}
        borderColor={'neutral.300'}
      >
        <Text lineHeight={'26px'} fontSize={'16px'} fontWeight={700}>
          {name} (Me)
        </Text>
        <Icon as={TargetIcon} w={7} h={'auto'} color={'neutral.200'} />
        <Text
          lineHeight={'26px'}
          fontSize={'16px'}
          color={'neutral.400'}
          fontWeight={'700'}
        >
          {score}
        </Text>
      </Grid>
    </ProgressLandingCard>
  );
}
